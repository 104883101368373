import $ from 'jquery';

import { Splide } from '@splidejs/splide';
import { AutoScroll } from '@splidejs/splide-extension-auto-scroll';

import '@splidejs/splide/dist/css/splide-core.min.css';  

import './../../scss/blocks/block-reference.scss';


$(document).ready(function() {

    var slides = document.getElementsByClassName("reference1-splide");
  
    for (var i = 0; i < slides.length; i++) {
      //Distribute(slides.item(i));
      var reference1swiper = new Splide( slides[i], {
        type   : 'loop',
        drag   : 'free',
        focus  : 'center',
        arrows: false,
        fixedWidth : '220px',
        mediaQuery: 'min',
        gap: '24px',
        pagination: false,
        autoScroll: {
          speed: 0.5,
        },
        breakpoints: {
          992: {
            fixedWidth : '300px',
          },
      }
      } ).mount( { 
        AutoScroll         
    } );
    }

    var slides2 = document.getElementsByClassName("reference2-splide");
  
    for (var i = 0; i < slides2.length; i++) {
      //Distribute(slides.item(i));
      var reference2swiper = new Splide( slides2[i], {
        type   : 'loop',
        drag   : 'free',
        focus  : 'center',
        arrows: false,
        fixedWidth : '220px',
        mediaQuery: 'min',
        gap: '24px',
        pagination: false,
        autoScroll: {
          speed: -0.5,
        },
        breakpoints: {
          992: {
            fixedWidth : '300px',
          },
      }
      } ).mount( { 
        AutoScroll         
    } );
    }

    
    
  
})